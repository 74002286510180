import { ref, watch, computed } from '@vue/composition-api'
import permissionRepository from '@/repository/permissionRepository'
import store from '@/store'

export default function permissionRuleList() {
  const refPermissionRoleListTable = ref(null)

  const tableColumns = [
    { key: 'role', label: 'Роль', sortable: true },
    { key: 'title', label: 'Наименование', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ]

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('role')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refPermissionRoleListTable.value ? refPermissionRoleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refPermissionRoleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    permissionRepository.rolesList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
    })
      .then(response => {
        const { items, count } = response

        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  return {
    fetchItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPermissionRoleListTable,
    refetchData,
  }
}
