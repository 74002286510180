<template>
  <b-sidebar
    id="add-new-permission-rule-sidebar"
    :visible="isAddNewPermissionRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-permission-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить новую роль
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name=" "
            rules="required"
          >
            <b-form-group
              label="Роль"
              label-for="role"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="role"
                v-model="rolesData.role"
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="  "
            rules="required"
          >
            <b-form-group
              label="Название"
              label-for="title"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="title"
                v-model="rolesData.title"
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

localize('ru', ru)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BButton,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPermissionRoleSidebarActive',
    event: 'update:is-add-new-permission-role-sidebar-active',
  },
  props: {
    isAddNewPermissionRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    async onSubmit() {
      await this.createRoles()
      this.$swal({
        icon: 'success',
        title: 'Создание роли!',
        text: 'Роль успешно создана.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    },
  },
  setup(props, { emit }) {
    // const toast = useToast()

    const blankData = {
      role: '',
      title: '',
    }

    const rolesData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetRulesData = () => {
      rolesData.value = JSON.parse(JSON.stringify(blankData))
    }

    const typeOptions = [
      { label: 'can', value: 'can' },
      { label: 'cannot', value: 'cannot' },
    ]

    const createRules = () => new Promise(resolve => {
      // permissionService.addRules(rulesData.value)
      //   .then(() => {
      //     emit('refetch-data')
      //     emit('update:is-add-new-permission-rule-sidebar-active', false)
      //     resolve(true)
      //   })
      //   .catch(error => {
      //     toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Ошибка создания роли',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'danger',
      //         text: error ? `${error}`.replace('Error: ', '') : '',
      //       },
      //     })
      //   })
      emit('update:is-add-new-permission-role-sidebar-active', false)
      resolve()
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRulesData)

    return {
      rolesData,
      createRules,
      typeOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
